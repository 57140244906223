import React, { ReactNode } from 'react'
import styled from 'styled-components'
import SignInGraphic from 'assets/onboarding/get-started-1.svg'
import theme from 'stories/utils/theme'

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh - var(--navbar-height));

    @media (max-width: 750px) {
        grid-template-columns: 1fr;
    }
`

const LeftContainer = styled.div`
    display: flex;
    justify-content: center;

    & svg {
        max-width: 500px;
    }

    @media (max-width: 750px) {
        display: none;
    }
`

const RightContainer = styled.div`
    display: grid;
    place-content: center;
`

const AuthContainer = styled.div`
    background: white;
    border: 1px solid gray;
    border-radius: 5px;
    max-width: 500px;
    padding: 30px;
    display: grid;
    place-items: center;
    @media (max-width: 750px) {
        border: 0;
        padding: 0;
        margin: 0 15px;
    }
`

export const AuthLinksWrapper = styled.div`
    display: flex;
    flex-direction: column;

    && p,
    a {
        cursor: pointer;
        text-align: center;
        padding: 0;
    }
`

interface Props {
    children: ReactNode
}

function AuthWrapper(props: Props) {
    return (
        <Container>
            <LeftContainer>
                <SignInGraphic />
            </LeftContainer>

            <RightContainer>
                <AuthContainer>{props.children}</AuthContainer>
            </RightContainer>
        </Container>
    )
}

export default AuthWrapper
