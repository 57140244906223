import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import theme from '../utils/theme'
import { Headline5 } from 'stories/elements/Typography/Text'

const Container = styled.div<{ showProGradient?: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    .left-section,
    .right-section {
        display: grid;
        place-content: center;
    }
    .left-section section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .left-section {
        ${({ showProGradient }) =>
            showProGradient
                ? css`
                      background: rgb(82, 240, 141);
                      background: linear-gradient(140deg, rgba(82, 240, 141, 1) 0%, rgba(41, 98, 255, 1) 100%);
                      background-blend-mode: lighten;
                  `
                : css`
                      background: linear-gradient(180.02deg, #150620 0.01%, #14183a 50%, #150620 99.99%);
                  `}
        padding: 5%;
        h5 {
            padding: 10px 15% 0 15%;
        }
        img {
            margin: 0 auto;
            max-width: 100%;
            display: block;
        }
    }
    .right-section {
        background: linear-gradient(180.02deg, #150620 0.01%, #14183a 50%, #150620 99.99%);
    }
    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
        min-height: auto;

        .left-section {
            padding-top: 100px;
            background-color: ${theme.colors.white};
            img {
                width: 200px;
                height: 200px;
                margin: 0 auto;
                display: block;
            }
            section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0;
            }
        }
    }
`

interface Props {
    image: ReactNode
    children: ReactNode
    caption?: string
    showProGradient?: boolean
}

const SideSection = ({ image, children, showProGradient, caption }: Props) => {
    return (
        <Container showProGradient={showProGradient}>
            <div className="left-section">
                <section>{image}</section>
                {caption ? (
                    <Headline5
                        fontFamily="var(--font-inter)"
                        fontWeight={500}
                        textAlign="center"
                        color={theme.colors.deepSeaBlue}
                    >
                        {caption}
                    </Headline5>
                ) : null}
            </div>
            <div className="right-section">{children}</div>
        </Container>
    )
}

export default SideSection
